import PostList from 'components/blog/PostList';
import { Layout, Seo } from 'components/common';
import { Intro } from 'components/sections';
import { graphql } from 'gatsby';
import React from 'react';

const Tag = (props) => {
  const { data, pageContext } = props;
  const { edges: posts, totalCount } = data.allWpPost;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: tag } = pageContext;
  // const title = `${totalCount} post${
  //   totalCount === 1 ? '' : 's'
  // } with the tag ${tag}`

  const title = `#${tag}`;

  return (
    <Layout context={pageContext}>
      <Seo title={`${title} Tag | ${siteTitle}`} />
      <Intro title={`${title}`} subtitle={`${totalCount} Post(s) in Topic`} />
      <PostList posts={posts} />
    </Layout>
  );
};

export default Tag;

export const pageQuery = graphql`
  query TagPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
